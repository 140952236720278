import { Endpoint, FilterOperations, Method } from '../../../infrastructure';
import { CalendlyUserExtended } from '../base';
import { z } from 'zod';

export const CalendlyUserListFilterSchema = z.object({
  person_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  name: z.record(z.enum([FilterOperations.CONTAIN]), z.string()).optional(),
});

export type CalendlyUserListFilter = z.infer<typeof CalendlyUserListFilterSchema>;

export const CalendlyUserListQuerySchema = z.object({
  filters: CalendlyUserListFilterSchema.optional(),
});
export type CalendlyUserListQuery = z.infer<typeof CalendlyUserListQuerySchema>;

export type CalendlyUserListResponse = CalendlyUserExtended[];

export type CalendlyUserListEndpoint = Endpoint<
  Method.GET,
  `/api/v2/calendly-users`,
  CalendlyUserListResponse,
  undefined,
  CalendlyUserListQuery,
  undefined
>;
