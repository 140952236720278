import { z } from 'zod';
import { InterviewRequestReportItemSchema } from './report';
import { dateString, Endpoint, FilterOperations, Method } from '../../../infrastructure';

export const IRBacklogReportItemSchema = InterviewRequestReportItemSchema.pick({
  interview_calendar_event_id: true,
  interview_request_id: true,
  application_id: true,
  application_name: true,
  job_id: true,
  is_scheduled: true,
  is_priority: true,
  act_recruiter_id: true,
  act_recruiter_name: true,
  req_recruiter_id: true,
  req_recruiter_name: true,
  stage: true,
  ts_interview_request: true,
  dt_start: true,
  dt_end: true,
  dt_scheduled: true,
  dt_conducted: true,
  dt_canceled: true,
  expected_daily_interview: true,
}).extend({
  job_title: z.string(),
  position_id: z.string().nullable(),
  position_name: z.string().nullable(),
  project_id: z.string().nullable(),
  project_name: z.string().nullable(),
  client_id: z.string().nullable(),
  client_name: z.string().nullable(),
});
export type IRBacklogReportItem = z.infer<typeof IRBacklogReportItemSchema>;

export const IRBackLogReportFiltersSchema = z
  .object({
    dt_range_start: z.record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), dateString),
    dt_range_end: z.record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), dateString),
    stage: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    act_recruiter_id: z.record(z.enum([FilterOperations.IN]), z.string()),
    req_recruiter_id: z.record(z.enum([FilterOperations.IN]), z.string()),
  })
  .partial();

export type IRBackLogReportFilters = z.infer<typeof IRBackLogReportFiltersSchema>;

export const IRBackLogReportQuerySchema = z.object({
  filters: IRBackLogReportFiltersSchema,
});

export type IRBackLogReportQuery = z.infer<typeof IRBackLogReportQuerySchema>;

export type IRBackLogReportResponse = IRBacklogReportItem[];

export type IRBackLogReportEndpoint = Endpoint<
  Method.GET,
  '/interview-requests/backlog-report',
  IRBackLogReportResponse,
  undefined,
  IRBackLogReportQuery,
  undefined
>;
