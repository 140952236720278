import { CalendlyEventType } from '../base';
import { CalendlyEventTypeUpdateSchema } from '../crud';
import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const CalendlyEventTypeUpdateRequestSchema = CalendlyEventTypeUpdateSchema.pick({
  is_default: true,
  is_priority: true,
  is_enabled: true,
  email_template: true,
});
export type CalendlyEventTypeUpdateRequest = z.infer<typeof CalendlyEventTypeUpdateRequestSchema>;

export type CalendlyEventTypeUpdateParams = Pick<CalendlyEventType, 'calendly_event_type_id'>;

export type CalendlyEventTypeUpdateResponse = CalendlyEventType;

export type CalendlyEventTypeUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/calendly-event-types/${CalendlyEventTypeUpdateParams['calendly_event_type_id']}`,
  CalendlyEventTypeUpdateResponse,
  CalendlyEventTypeUpdateParams,
  undefined,
  CalendlyEventTypeUpdateRequest
>;
