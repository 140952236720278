import { FiltersQuery, PaginationQuery, SorterQuery } from '@ct-internal/api';
import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

type ApiQuery<
  F extends FiltersQuery,
  S extends SorterQuery | undefined,
  P extends PaginationQuery | undefined,
  T extends {} | undefined,
> = T extends {}
  ? { filters: F; sorter: S; pagination: P; params: T }
  : P extends PaginationQuery
  ? { filters: F; sorter: S; pagination: P }
  : S extends SorterQuery
  ? { filters: F; sorter: S }
  : { filters: F };

/**
 * The hook prevents making multiple requests caused by the single change.
 * I.e. resetting filters also resets the pagination and both operations should be awaited.
 */
const useApiQuery = <
  F extends FiltersQuery,
  S extends SorterQuery | undefined,
  P extends PaginationQuery | undefined,
  T extends {} | undefined,
>(
  filters: F,
  sorter?: S,
  pagination?: P,
  params?: T,
) => {
  const [query, setQuery] = useState<{ filters: F; sorter?: S; pagination?: P; params?: T }>({
    filters,
    sorter,
    pagination,
    params,
  });
  const createQuery = useCallback(
    debounce(
      (filters: F, sorter?: S, pagination?: P, params?: T) => {
        setQuery({ filters, sorter, pagination, params });
      },
      500,
      { leading: false },
    ),
    [],
  );
  useEffect(() => {
    if (
      query.filters === filters &&
      query.sorter === sorter &&
      query.pagination === pagination &&
      query.params === params
    ) {
      // prevent updating state on the first mount
      return;
    }
    createQuery(filters, sorter, pagination, params);
  }, [JSON.stringify({ filters, sorter, pagination, params })]);

  return query as ApiQuery<F, S, P, T>;
};

export default useApiQuery;
