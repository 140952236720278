import { z } from 'zod';
import { TSearchFilter } from '../../table-schemas';
import { Endpoint, Method, stringToBoolean } from '../../../../infrastructure';
import { ApplicationSearchFiltersSchema } from '../search';

export const UpdateApplicationSearchFilterRequestSchema = z.object({
  name: z.string(),
  description: z.string().nullish(),
  params: ApplicationSearchFiltersSchema,
  is_public: stringToBoolean,
});

export type UpdateApplicationSearchFilterRequest = z.infer<
  typeof UpdateApplicationSearchFilterRequestSchema
>;

export type UpdateApplicationSearchFilterParams = { filter_id: TSearchFilter['filter_id'] };

export type UpdateApplicationSearchFilterResponse = {};

export type UpdateApplicationSearchFilterEndpoint = Endpoint<
  Method.PUT,
  '/application-search/filters',
  UpdateApplicationSearchFilterResponse,
  UpdateApplicationSearchFilterParams,
  {},
  UpdateApplicationSearchFilterRequest
>;
