import { z } from 'zod';
import { Endpoint, Method, stringToBoolean } from '../../../../infrastructure';
import { TSearchFilter } from '../../table-schemas';
import { ApplicationSearchFiltersSchema } from '../search';

export const CreateApplicationSearchFilterRequestSchema = z.object({
  name: z.string(),
  description: z.string().nullish(),
  params: ApplicationSearchFiltersSchema,
  is_public: stringToBoolean,
});
export type CreateApplicationSearchFilterRequest = z.infer<
  typeof CreateApplicationSearchFilterRequestSchema
>;

export type CreateApplicationSearchFilterResponse = { filter_id: TSearchFilter['filter_id'] };

export type CreateApplicationSearchFilterEndpoint = Endpoint<
  Method.POST,
  '/application-search/filters',
  CreateApplicationSearchFilterResponse,
  {},
  {},
  CreateApplicationSearchFilterRequest
>;
