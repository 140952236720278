import { z } from 'zod';
import { CalendlyEventTypeSchema } from '../base';

export const CalendlyEventTypeFindSchema = CalendlyEventTypeSchema.pick({
  calendly_event_type_id: true,
  calendly_user_id: true,
  is_priority: true,
  is_enabled: true,
  is_default: true,
}).partial();
export type CalendlyEventTypeFind = z.infer<typeof CalendlyEventTypeFindSchema>;
