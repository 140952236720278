import { z } from 'zod';
import { InterviewCalendarEventSchema } from '../base';

export const InterviewCalendarEventCreateSchema = InterviewCalendarEventSchema.pick({
  //Required
  interview_request_id: true,

  zoom_id: true,
  act_recruiter_id: true,
  calendly_event_id: true,
  google_calendar_event_id: true,
  status: true,
  dt_canceled: true,
  dt_conducted: true,
  dt_end: true,
  dt_start: true,
  dt_last_google_update: true,
  is_priority: true,
  calendly_event_type_id: true,
}).partial({
  zoom_id: true,
  act_recruiter_id: true,
  calendly_event_id: true,
  google_calendar_event_id: true,
  status: true,
  dt_canceled: true,
  dt_conducted: true,
  dt_end: true,
  dt_start: true,
  dt_last_google_update: true,
  is_priority: true,
  calendly_event_type_id: true,
});
export type InterviewCalendarEventCreate = z.infer<typeof InterviewCalendarEventCreateSchema>;
