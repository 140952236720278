import { Endpoint, Method } from '../../../infrastructure';
import { InterviewCalendarEvent } from '../base';
import { z } from 'zod';

export const ICEGetOverlappingParamsSchema = z.object({
  interview_calendar_event_id: z.coerce.number(),
});
export type ICEGetOverlappingParams = z.infer<typeof ICEGetOverlappingParamsSchema>;

export type ICEGetOverlappingResponse = InterviewCalendarEvent | null;

export type ICEGetOverlappingEndpoint = Endpoint<
  Method.GET,
  `/api/v2/interview-calendar-events/overlapping/${ICEGetOverlappingParams['interview_calendar_event_id']}`,
  ICEGetOverlappingResponse,
  ICEGetOverlappingParams,
  undefined,
  undefined
>;
