import { z } from 'zod';
import { dateString } from '../../infrastructure';
import { HireGroup } from '..';

export enum PositionStaffingStatus {
  'Staffing' = 'STAFFING',
  'Recruiting' = 'RECRUITING',
  'Offered' = 'OFFERED',
  'Filled' = 'FILLED',
  'Canceled' = 'CANCELED',
  'Changed Spec' = 'CHANGED SPEC',
  'Changed Rectg' = 'CHANGED RECTG',
  'Changed DtStaffit' = 'CHANGED DTSTAFFIT',
}

export const PositionSpecsSchema = z.object({
  skills: z.array(z.string()).nullable(),
  seniority: z.string().nullable(),
  english_level: z.number().nullable(),
});
export type PositionSpecs = z.infer<typeof PositionSpecsSchema>;

export const PositionStaffingSchema = z.object({
  position_staffing_id: z.number(),
  next_position_staffing_id: z.number().nullable(),
  position_id: z.string(),
  position_specs: PositionSpecsSchema.nullable(),
  dt_staffit: dateString,
  dt_recruiting: dateString.nullable(),
  dt_oferred: dateString.nullable(),
  dt_filled: dateString.nullable(),
  dt_canceled: dateString.nullable(),
  dt_changed_spec: dateString.nullable(),
  dt_changed_rectg: dateString.nullable(),
  dt_changed_dt_staffit: dateString.nullable(),
  status: z.nativeEnum(PositionStaffingStatus).default(PositionStaffingStatus.Staffing),
  recruiting_category: z.nativeEnum(HireGroup),
  assignment_id: z.string().nullable(),
  person_id: z.string().nullable(),
  previous_assignment_id: z.string().nullable(),
  closed: z.boolean().default(false),
  dt_created: dateString,
  dt_updated: dateString.nullable(),
});
export type PositionStaffing = z.infer<typeof PositionStaffingSchema>;

export const PositionStaffingByApplicationSchema = z
  .object({
    project_name: z.string(),
    position_name: z.string(),
    client_name: z.string(),
    client_id: z.string(),
    project_id: z.string(),
    position_id: z.string(),
    dt_created: dateString,
    dt_updated: dateString.nullable(),
  })
  .nullable();
export type PositionStaffingByApplication = z.infer<typeof PositionStaffingByApplicationSchema>;
