import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const CalendlyApiUserTypes = ['Round Robin', 'User', 'Team'] as const;
export const CalendlyUserSchema = z.object({
  calendly_user_id: z.string(),
  calendly_user_type_id: z.string(),
  person_id: z.string().nullable(),
  category: z.string().nullable(),
  path: z.string().nullable(),
  email_template: z.string().nullable(),
  name: z.string().nullable(),
  key: z.string().nullable(),

  calendly_api_type: z.enum(CalendlyApiUserTypes),
  calendly_api_user_id: z.string().nullable(),
  calendly_api_user_uri: z.string().nullable(),
  calendly_api_user_slug: z.string().nullable(),
  webhook_re: z.string().nullable(),

  dt_created: dateString,
  dt_updated: dateString,
});
export type CalendlyUser = z.infer<typeof CalendlyUserSchema>;

export const CalendlyAPIUserSchema = z.object({
  user: z.object({
    uri: z.string(),
    slug: z.string(),
  }),
});
export type CalendlyAPIUser = z.infer<typeof CalendlyAPIUserSchema>;

export const CalendlyUserExtendedSchema = CalendlyUserSchema.extend({
  email: z.string().nullable(),
  recruiter_name: z.string().nullable(),
  calendly_user_type_name: z.string(),
});
export type CalendlyUserExtended = z.infer<typeof CalendlyUserExtendedSchema>;
