import { z } from 'zod';
import { Lead, LeadSchema } from './base';
import { Endpoint, Method } from '../../infrastructure';

export const LeadUpdateSchema = LeadSchema.pick({
  project_id: true,
  client_id: true,
  dtplannedend: true,
  dtplannedstart: true,
  hard_date: true,
  name: true,
  timezone: true,
  expected_monthly_revenue: true,
  expected_monthly_cost: true,
  expected_team_size: true,
  owner_id: true,
  sf_description: true,
  sf_description_updated_at: true,
  sf_notes_updated_at: true,
  sf_stage: true,
  sf_expected_monthly_revenue: true,
  duration_weeks: true,
  sf_locked_logs: true,
  is_sf_locked: true,
  is_draft_opportunity: true,
  is_starter_project: true,
  confidence_level: true,
  sf_next_update_on: true,
  sow_link: true,
  sow_dt_signed: true,
  sf_active_status: true,
  sf_probability: true,
  sf_financial_probability: true,
  sf_exp_dt_close: true,
})
  .extend({
    is_confirmed: z.boolean().nullable(),
    is_conflict_confirmed: z.boolean().nullable(),
    duration: z.number().nullable(),
    update_salesforce: z.boolean().nullish(),
  })
  .partial({
    duration_weeks: true,
    owner_id: true,
    sf_description: true,
    sf_description_updated_at: true,
    sf_notes_updated_at: true,
    sf_stage: true,
    sf_expected_monthly_revenue: true,
    sf_locked_logs: true,
    is_sf_locked: true,
    sf_next_update_on: true,
    sow_link: true,
    sow_dt_signed: true,
    sf_active_status: true,
    sf_probability: true,
    sf_financial_probability: true,
    sf_exp_dt_close: true,
  });
export type LeadUpdate = z.infer<typeof LeadUpdateSchema>;

export const LeadUpdateBodySchema = LeadUpdateSchema.pick({
  project_id: true,
  client_id: true,
  dtplannedend: true,
  dtplannedstart: true,
  duration: true,
  hard_date: true,
  name: true,
  timezone: true,
  expected_monthly_revenue: true,
  sf_expected_monthly_revenue: true,
  expected_monthly_cost: true,
  expected_team_size: true,
  is_confirmed: true,
  is_conflict_confirmed: true,
  is_draft_opportunity: true,
  is_starter_project: true,
  confidence_level: true,
  sf_stage: true,
}).partial({
  client_id: true,
  dtplannedend: true,
  dtplannedstart: true,
  duration: true,
  hard_date: true,
  name: true,
  timezone: true,
  sf_expected_monthly_revenue: true,
  expected_monthly_revenue: true,
  expected_monthly_cost: true,
  expected_team_size: true,
  is_draft_opportunity: true,
  is_starter_project: true,
  confidence_level: true,
  sf_stage: true,
});

export type LeadUpdateBody = z.infer<typeof LeadUpdateBodySchema>;

export const LeadUpdateParamsSchema = z.object({
  project_id: z.string(),
});
export type LeadUpdateParams = z.infer<typeof LeadUpdateParamsSchema>;

export type LeadUpdateResponse = Lead;

export type LeadUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql-leads/${LeadUpdateParams['project_id']}`,
  LeadUpdateResponse,
  LeadUpdateParams,
  undefined,
  LeadUpdateBody
>;
