import { Endpoint, Method } from '../../../infrastructure';
import { CalendlyUser } from '../base';
import { CalendlyUserCreateSchema } from '../crud';
import { z } from 'zod';

export const CalendlyUserCreateRequestSchema = CalendlyUserCreateSchema.pick({
  person_id: true,
  calendly_api_type: true,
  category: true,
  calendly_user_type_id: true,
  path: true,
  name: true,
  email_template: true,
  key: true,
}).partial({
  email_template: true,
  category: true,
  path: true,
  name: true,
  person_id: true,
  key: true,
});
export type CalendlyUserCreateRequest = z.infer<typeof CalendlyUserCreateRequestSchema>;

export type CalendlyUserCreateResponse = CalendlyUser;

export type CalendlyUserCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/calendly-users/`,
  CalendlyUserCreateResponse,
  undefined,
  undefined,
  CalendlyUserCreateRequest
>;
