import { z } from 'zod';

export const TSkillNodeSchema = z.object({
  skill_id: z.number(),

  name: z.string(),
  path: z.string(),

  level: z.number(),

  is_visible: z.boolean(),
  is_selectable: z.boolean(),

  created_by: z.string().nullable(),
  updated_by: z.string().nullable(),

  dt_created: z.number(),
  dt_updated: z.number().nullable(),
  dt_archived: z.number().nullable(),
});

export type TSkillNode = z.infer<typeof TSkillNodeSchema>;

export const TSkillNodeCreateSchema = TSkillNodeSchema.pick({
  name: true,
  path: true,
}).extend({
  is_visible: z.boolean().optional(),
  is_selectable: z.boolean().optional(),
  created_by: z.string().nullable().optional(),
});

export type TSkillNodeCreate = z.infer<typeof TSkillNodeCreateSchema>;

export const TSkillNodeUpdateSchema = TSkillNodeSchema.pick({
  name: true,
  path: true,
  is_visible: true,
  is_selectable: true,
  dt_archived: true,
  updated_by: true,
}).partial();

export type TSkillNodeUpdate = z.infer<typeof TSkillNodeUpdateSchema>;

export const SkillNodeItemSchema = TSkillNodeSchema.extend({
  children: z.array(TSkillNodeSchema).optional(),
});

export type SkillNodeItem = z.infer<typeof SkillNodeItemSchema>;
