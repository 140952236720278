import { z } from 'zod';
import { InterviewCalendarEventSchema } from '../../interview-calendar-event';
import { InterviewRequestSchema } from '../base';
import { dateString, Endpoint, FilterOperations, Method } from '../../../infrastructure';
import { TJobSchema } from '../../jobs';

export const InterviewRequestReportItemSchema = InterviewRequestSchema.pick({
  interview_request_id: true,
  req_recruiter_id: true,
  ask_recruiter_id: true,
  application_id: true,
  dt_canceled: true,
  dt_conducted: true,
  dt_scheduled: true,
  num_days: true,
  stage: true,
  ts_interview_request: true,
})
  .merge(
    InterviewCalendarEventSchema.pick({
      act_recruiter_id: true,
      dt_start: true,
      dt_end: true,
      attendee_response: true,
      interview_calendar_event_id: true,
      status: true,
      token: true,
      sequence: true,
      calendly_reschedule_url: true,
      calendly_event_type_id: true,
    }),
  )
  .merge(
    TJobSchema.pick({
      job_id: true,
      title: true,
    }),
  )
  .extend({
    req_recruiter_name: z.string(),
    ask_recruiter_name: z.string(),
    act_recruiter_name: z.string(),
    application_name: z.string(),
    is_scheduled: z.boolean(),
    is_priority: z.boolean(),
    expected_daily_interview: z.number(),
    app_booked_over_id: z.string().nullable(),
    app_booked_over_name: z.string().nullable(),
  });
export type InterviewRequestReportItem = z.infer<typeof InterviewRequestReportItemSchema>;

export const InterviewRequestReportFiltersSchema = z
  .object({
    act_recruiter_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    req_recruiter_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    application_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    dt_range_start: z.record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), dateString),
    dt_range_end: z.record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), dateString),
    stage: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    is_interview_priority: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
    is_pending: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
  })
  .partial();
export type InterviewRequestReportFilters = z.infer<typeof InterviewRequestReportFiltersSchema>;

export const InterviewRequestReportQuerySchema = z.object({
  filters: InterviewRequestReportFiltersSchema.optional(),
});
export type InterviewRequestReportQuery = z.infer<typeof InterviewRequestReportQuerySchema>;

export type InterviewRequestReportResponse = InterviewRequestReportItem[];

export type InterviewRequestReportEndpoint = Endpoint<
  Method.GET,
  '/interview-requests/report',
  InterviewRequestReportResponse,
  undefined,
  InterviewRequestReportQuery,
  undefined
>;
