import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TCountry } from '../table-schemas';

export const ListCountriesSlimQuerySchema = z
  .object({
    text: z.string().optional(),
  })
  .partial();
export type ListCountriesSlimQuery = z.infer<typeof ListCountriesSlimQuerySchema>;

export type ListCountriesSlimResponseItem = {
  country_id: TCountry['country_id'];
  name: TCountry['name'];
};

export type ListCountriesSlimResponse = ListCountriesSlimResponseItem[];

export type ListCountriesSlimEndpoint = Endpoint<
  Method.GET,
  `/api/v2/countries/slim`,
  ListCountriesSlimResponse,
  undefined,
  ListCountriesSlimQuery,
  undefined
>;
