import { z } from 'zod';
import { Method, Endpoint } from '../../../infrastructure';
import { TAppSourceChannel } from '../table-schemas';

export const CreateAppSourceChannelRequestSchema = z.object({
  title: z.string(),
});

export type CreateAppSourceChannelRequest = z.infer<typeof CreateAppSourceChannelRequestSchema>;

export type CreateAppSourceChannelResponse = TAppSourceChannel;

export type CreateAppSourceChannelEndpoint = Endpoint<
  Method.POST,
  '/api/v2/applications/source-channels',
  CreateAppSourceChannelResponse,
  undefined,
  undefined,
  CreateAppSourceChannelRequest
>;
