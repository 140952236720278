import { InterviewRequest, InterviewRequestSchema } from '../../interview-request';
import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { InterviewCalendarEventSchema } from '../base';

export const ICESlotSwapRequestSchema = InterviewRequestSchema.pick({
  application_id: true,
  stage: true,
})
  .merge(
    InterviewCalendarEventSchema.pick({
      interview_calendar_event_id: true,
    }),
  )
  .extend({
    act_recruiter_id: z.string(),
    calendly_event_type_id: z.string().nullish(),
  });

export type ICESlotSwapRequest = z.infer<typeof ICESlotSwapRequestSchema>;

export type ICESlotSwapResponse = {};

export type ICESlotSwapEndpoint = Endpoint<
  Method.POST,
  '/api/v2/interview-calendar-events/slot-swap-manually',
  ICESlotSwapResponse,
  undefined,
  undefined,
  ICESlotSwapRequest
>;
