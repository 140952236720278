import { z } from 'zod';
import { dateString } from '../../infrastructure';

export enum PsnRctrGroup {
  'Application' = 'Application',
  'Sourcing' = 'Sourcing',
  'Other' = 'Other',
}

export enum PsnRctrRole {
  '1Int' = '1st Interviewer',
  '2Int' = '2nd Interviewer',
  'Mng' = 'Management',
  'Tech' = 'Tech',
  'HR' = 'HR',
}

export const PsnRctrSchema = z.object({
  person_id: z.string(),
  email: z.string(),
  rctr_group: z.nativeEnum(PsnRctrGroup),
  rctr_role: z.nativeEnum(PsnRctrRole),
  zoom_link: z.string().nullable(),
  expected_daily_interview: z.number(),
  dt_created: dateString,
  dt_updated: dateString,
});

export type PsnRctr = z.infer<typeof PsnRctrSchema>;

export const PsnRctrExtendedSchema = PsnRctrSchema.extend({
  name: z.string().nullable(),
  slack_id: z.string().nullable(),
});

export type PsnRctrExtended = z.infer<typeof PsnRctrExtendedSchema>;
