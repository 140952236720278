import { z } from 'zod';
import { ContractTypes } from '../contract-templates';

export const TCoachAiCheckinSchema = z.object({
  checkin_id: z.number(),
  person_id: z.string(),
  coach_id: z.string(),
  checkin_at: z.string().datetime(),
  summary: z.string(),
  status: z.enum(['success', 'fail', 'incomplete']),
  dt_reviewed: z.string().datetime().nullable(),
});
export type TCoachAiCheckin = z.infer<typeof TCoachAiCheckinSchema>;

export const TTargetGroupSchema = z.object({
  group_id: z.number(),
  name: z.string(),
  description: z.string().nullable(),
});
export type TTargetGroup = z.infer<typeof TTargetGroupSchema>;

export const TTargetGroupPsnAscSchema = z.object({
  group_id: z.number(),
  person_id: z.string(),
});
export const TargetGroupSchema = TTargetGroupSchema.extend({
  emails: z.array(z.string()),
});
export type TargetGroup = z.infer<typeof TargetGroupSchema>;

export const PersonDetailsSchema = z.object({
  id: z.string(),
  name: z.string(),
  avatar_url: z.string().nullable(),
});

export const CoachAiCheckinStatuses = ['success', 'fail', 'incomplete'] as const;
export type CoachAiCheckinStatus = (typeof CoachAiCheckinStatuses)[number];

export const CoachAiCheckinSchema = z.object({
  id: z.number(),
  person: PersonDetailsSchema,
  coach: PersonDetailsSchema.nullable(),
  project: z.string().nullable(),
  client: z.string().nullable(),
  date: z.string().datetime(),
  is_reviewed: z.boolean(),
  status: z.enum(CoachAiCheckinStatuses),
  summary: z.string().nullable(),
  target_groups: z.array(z.string()),
});
export type CoachAiCheckin = z.infer<typeof CoachAiCheckinSchema>;

export const CoachAiCheckinMetricsSchema = z.object({
  participation_rate: z.number().optional(),
  checkin_count: z.number().optional(),
  people_count: z.number().optional(),
});
export type CoachAiCheckinMetrics = z.infer<typeof CoachAiCheckinMetricsSchema>;

export const PersonInfoSchema = z.object({
  person_id: z.string(),
  hire_date: z.string().datetime(),
  name: z.string(),
  slack_id: z.string(),
  last_checkin_time: z.string().datetime(),
  department: z.string(),
  target_groups: z.array(z.string()),
  coach_id: z.string().nullable(),
  contract_type: z.enum(ContractTypes),
});
export type PersonInfo = z.infer<typeof PersonInfoSchema>;
