import { z } from 'zod';
import { TJobSchema, TJobPrioritySchema, TJobCategorySchema } from '../table-schemas';
import {
  Endpoint,
  Method,
  makeSorter,
  FilterOperations,
  ReportsQuerySchema,
  ReportResponse,
} from '../../../infrastructure';
import { JobStatusSchema, OwnerExpandedSchema } from '../base';

export const ListJobsItemSchema = TJobSchema.pick({
  job_id: true,
  job_family_id: true,
  title: true,
  slug: true,
  n_sort: true,
  job_short_name: true,
  level: true,
  dtcreate: true,
  dtupdate: true,
}).extend({
  priority: TJobPrioritySchema.shape.name, // Hot, Evergreen
  status: JobStatusSchema,
  on_deck_visibility: z.boolean(),
  category: TJobCategorySchema.shape.name.nullable(),
  sub_category: TJobCategorySchema.shape.name.nullable(),
  hiring: z.number(),
  owner: OwnerExpandedSchema.nullable(),
  application_counts: z.object({
    application_count: z.number(),
    stage1_count: z.number(),
    stage2_count: z.number(),
    stage3_count: z.number(),
    stage4_count: z.number(),
    stage5_count: z.number(),
    on_deck_count: z.number(),
  }),
});

export type ListJobsItem = z.infer<typeof ListJobsItemSchema>;

export const ListJobsFiltersSchema = z
  .object({
    job_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()),
    level_ids: z.record(z.enum([FilterOperations.IN]), z.coerce.number().array()),
    category_ids: z.record(z.enum([FilterOperations.IN]), z.number().array()),
    status: z.record(z.enum([FilterOperations.IN]), JobStatusSchema.array()),
    recruiter_id: z.record(z.enum([FilterOperations.IN]), z.string().array()),
  })
  .partial();
export type ListJobsFilters = z.infer<typeof ListJobsFiltersSchema>;

export const ListJobsSorterSchema = makeSorter(
  z.enum([
    'n_sort',
    'title',
    'level',
    'sub_category',
    'status',
    'owner',
    'dtcreate',
    'num_applications',
  ]),
);
export type ListJobsSorter = z.infer<typeof ListJobsSorterSchema>;

export const ListJobsQuerySchema = ReportsQuerySchema.extend({
  filters: ListJobsFiltersSchema.optional(),
  sorter: ListJobsSorterSchema.optional(),
});
export type ListJobsQuery = z.infer<typeof ListJobsQuerySchema>;

export type ListJobsResponse = ReportResponse<ListJobsItem>;

export type ListJobsEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql/jobs',
  ListJobsResponse,
  {},
  ListJobsQuery,
  {}
>;
