import { z } from 'zod';
import { PsnRctrGoogleCalendarChannelSchema } from '../psn-rctr-google-calendar-channel';
import { dateString } from '../../infrastructure';

export enum GoogleCalendarEventStatus {
  'confirmed' = 'confirmed',
  'tentative' = 'tentative',
  'cancelled' = 'cancelled',
}
export enum GoogleCalendarEventAttendeResponse {
  'accepted' = 'accepted',
  'declined' = 'declined',
  'tentative' = 'tentative',
  'needsAction' = 'needsAction',
  'none' = 'none',
}

export enum GoogleCalendarEventResponseStatus {}

export const GoogleCalendarActor = z.object({
  email: z.string(),
});

export const GoogleCalendarDate = z.object({
  date: dateString.nullish(),
  dateTime: dateString.nullish(),
  timeZone: z.string().nullish(),
});

export const GoogleCalendarAttendeeSchema = GoogleCalendarActor.extend({
  responseStatus: z.nativeEnum(GoogleCalendarEventAttendeResponse),
});

export type GoogleCalendarAttendee = z.infer<typeof GoogleCalendarAttendeeSchema>;

export const ExternalGoogleCalendarEventSchema = z.object({
  id: z.string(),
  etag: z.string().nullish(),
  status: z.nativeEnum(GoogleCalendarEventStatus),
  created: dateString.nullish(),
  updated: dateString.nullish(),

  summary: z.string().nullish(),
  description: z.string().nullish(),
  location: z.string().nullish(),

  creator: GoogleCalendarActor.nullish(),
  organizer: GoogleCalendarActor.nullish(),
  start: GoogleCalendarDate,
  end: GoogleCalendarDate,
  attendees: z.array(GoogleCalendarAttendeeSchema).nullish(),
});
export type ExternalGoogleCalendarEvent = z.infer<typeof ExternalGoogleCalendarEventSchema>;

export const GoogleCalendarEventSchema = ExternalGoogleCalendarEventSchema.merge(
  PsnRctrGoogleCalendarChannelSchema.pick({
    calendar_id: true,
  }),
);
export type GoogleCalendarEvent = z.infer<typeof GoogleCalendarEventSchema>;
