import { Endpoint, Method } from '../../../infrastructure';
import { CalendlyUserType } from '../base';
import { CalendlyUserTypeFindSchema } from '../crud';
import { z } from 'zod';

export const CalendlyUserTypeListFilterSchema = CalendlyUserTypeFindSchema;

export type CalendlyUserTypeListFilter = z.infer<typeof CalendlyUserTypeListFilterSchema>;

export const CalendlyUserTypeListQuerySchema = z.object({
  filters: CalendlyUserTypeListFilterSchema.optional(),
});
export type CalendlyUserTypeListQuery = z.infer<typeof CalendlyUserTypeListQuerySchema>;

export type CalendlyUserTypeListResponse = CalendlyUserType[];

export type CalendlyUserTypeListEndpoint = Endpoint<
  Method.GET,
  `/api/v2/calendly-user-types`,
  CalendlyUserTypeListResponse,
  undefined,
  CalendlyUserTypeListQuery,
  undefined
>;
