import { z } from 'zod';

export const TPersonSchema = z.object({
  person_id: z.string().length(24),
  email: z.string().max(255).email().nullable(),
  firstname: z.string().max(30).nullable(),
  surname: z.string().max(40).nullable(),
  hiredfor: z.enum(['DEV', 'INTERNAL']).default('DEV'),
  dthired: z.date().nullable(),
  dtstart: z.date().nullable(),
  dtterminated: z.date().nullable(),
  dtcreate: z.date().default(new Date()),
  dtupdate: z.date().nullable(),
  dtmravailable: z.any().nullable(), // DateMultiRange type doesn't have direct Zod equivalent
  dtmrpasthires: z.any().nullable(), // DateMultiRange type doesn't have direct Zod equivalent
  latest_contract_id: z.number().int().nullable(),
  isdeleted: z.boolean().default(false),
  name: z.string().max(45).nullable(),
  timezone: z.string().nullable(),
  current_company_id: z.string().length(24).nullable(),
  role: z.string().max(60).nullable(),
  gravatar_url: z.string().max(120).nullable(),
  culture_contact_id: z.string().length(24).nullable(),
  hired_by: z.string().length(24).nullable(),
  warnings: z.any().nullable(), // JSONB type
  slack_id: z.string().max(50).nullable(),
  year_started_profession: z.number().int().nullable(),
  personal_email: z.string().max(255).email().nullable(),
  annual_salary: z.number().multipleOf(0.01).nullable(),
  country_id: z.string().length(2).nullable(),
  has_old_asn: z.boolean().default(false),
  contract_signed: z.boolean().default(false),
  person_sf_id: z.string().length(18).nullable(),
  zoom_link: z.string().max(100).nullable(),
  immigration_program: z.string().nullable(),
  dt_immigration_prog: z.date().nullable(),
  current_salary: z.number().multipleOf(0.01).nullable(),
  gender: z.string().max(24).nullable(),
  default_vacation_alloc: z.number().default(15.0),
  unaccented_fullname: z.string().max(255).nullable(),
  slack_internal_id: z.string().max(50).nullable(),
  is_agency: z.boolean().default(false),
  birthday: z.date().nullable(),
});

export type TPerson = z.infer<typeof TPersonSchema>;
