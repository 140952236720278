import { z } from 'zod';
import { CalendlyUserSchema } from '../base';

export const CalendlyUserFindSchema = CalendlyUserSchema.pick({
  calendly_user_id: true,
  calendly_api_type: true,
  calendly_user_type_id: true,
  person_id: true,
})
  .extend({
    email: z.string(),
    name: z.string(),
  })
  .partial();
export type CalendlyUserFind = z.infer<typeof CalendlyUserFindSchema>;
