import { config } from '../config';

import moment from 'moment';

interface User {
  accessToken: string;
  refreshToken: string;
  expires: number;
}

type UpdateUserCallback = (user: User) => void;
type SyncUserCallback = () => Promise<void>;
type LogoutCallback = () => Promise<void>;

class AuthService {
  private isLogin: boolean = false;
  private isLogout: boolean = false;
  private refreshPromise: Promise<User> | null = null;
  private user: User | null = null;
  private updateUserCallback: UpdateUserCallback | null = null;
  private syncUserCallback: SyncUserCallback | null = null;
  private logoutCallback: LogoutCallback | null = null;
  private userPromiseResolve: ((user: User) => void) | null = null;
  private fingerprint: string | null = null;
  private userAndFingerprintPromise: Promise<void> | null = null;
  private resolveUserAndFingerprint: (() => void) | null = null;
  private logoutPromise: Promise<void> | null = null;

  constructor() {
    this.userAndFingerprintPromise = new Promise((resolve) => {
      this.resolveUserAndFingerprint = resolve;
    });
  }

  setCallbacks(updateUser: UpdateUserCallback, logout: LogoutCallback, syncUser: SyncUserCallback) {
    this.updateUserCallback = updateUser;
    this.logoutCallback = logout;
    this.syncUserCallback = syncUser;
  }

  setUser(user: User | null) {
    this.user = user;
    this.checkUserAndFingerprint();
    if (user && this.userPromiseResolve) {
      this.userPromiseResolve(user);
      this.userPromiseResolve = null;
    }
  }

  setFingerprint(fingerprint: string) {
    this.fingerprint = fingerprint;
    this.checkUserAndFingerprint();
  }

  setIsLogin(isLogin: boolean) {
    this.isLogin = isLogin;
  }

  setIsLogout(isLogout: boolean) {
    this.isLogout = isLogout;
  }

  private checkUserAndFingerprint() {
    if (this.user && this.fingerprint && this.resolveUserAndFingerprint) {
      this.resolveUserAndFingerprint();
      this.resolveUserAndFingerprint = null;
    }
  }

  isTokenExpired(): boolean {
    if (!this.user) {
      return true;
    }
    const { expires } = this.user;
    const timeLeft = moment.unix(expires).diff(moment.utc());
    const result = timeLeft <= 30 * 1000;

    return result;
  }

  async getValidToken(): Promise<string> {
    if (this.isLogin) {
      this.isLogin = false;
      return 'initial';
    }

    if (this.syncUserCallback) {
      await this.syncUserCallback();
    }

    if (this.isLogout) {
      this.isLogout = false;
      await this.logoutCallback?.();
      return '';
    }

    await this.userAndFingerprintPromise;

    if (this.user && this.fingerprint && this.isTokenExpired()) {
      await this.refreshToken();
    }
    return this.user?.accessToken || '';
  }

  async refreshToken(): Promise<void> {
    console.debug('Refreshing token - JRL');
    if (this.logoutPromise) {
      await this.logoutPromise;
      return;
    }

    if (!this.refreshPromise) {
      this.refreshPromise = this.performRefresh();
    }

    try {
      const refreshedUser = await this.refreshPromise;
      if (this.updateUserCallback && this.user?.accessToken !== refreshedUser.accessToken) {
        this.updateUserCallback(refreshedUser);
      }
    } catch (error) {
      console.error('Token refresh failed:', error);

      if (this.logoutCallback && this.user) {
        this.logoutPromise = this.triggerLogoutCallback();
        await this.logoutPromise;
      }
      throw error;
    } finally {
      this.refreshPromise = null;
    }
  }

  private async triggerLogoutCallback(): Promise<void> {
    try {
      await this.logoutCallback?.();
    } finally {
      this.logoutPromise = null;
    }
  }

  private async performRefresh(): Promise<User> {
    const refreshToken = this.user?.refreshToken;
    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    if (!this.fingerprint) {
      throw new Error('Fingerprint not set');
    }

    const response = await fetch(`${config.AUTH_API_BASE}/api/v3/auth/refresh-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${refreshToken}`,
      },
      body: JSON.stringify({ refreshToken, fingerprint: this.fingerprint }),
    });

    if (!response.ok) {
      if (response.status === 401) {
        throw new Error('Refresh token expired');
      }
      throw new Error('Refresh token request failed');
    }

    return response.json();
  }
}

export const authService = new AuthService();
