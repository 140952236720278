import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const CalendlyUserTypeSchema = z.object({
  calendly_user_type_id: z.string(),
  name: z.string(),
  application_status: z.number(),
  dt_created: dateString,
  dt_updated: dateString,
});

export type CalendlyUserType = z.infer<typeof CalendlyUserTypeSchema>;
