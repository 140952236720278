import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export type GetUploadTemplateEndpoint = Endpoint<
  Method.GET,
  '/api/v2/uploads/template',
  GetUploadTemplateResponse,
  GetUploadTemplateParams,
  GetUploadTemplateQuery,
  undefined
>;

export type GetUploadTemplateParams = {
  templateType:
    | 'badges'
    | 'payouts'
    | 'raise_requests'
    | 'opp-extension-revenue'
    | 'swag_badge_refund';
};

export const GetUploadTemplateQuerySchema = z.object({
  templateType: z.enum([
    'badges',
    'payouts',
    'raise_requests',
    'opp-extension-revenue',
    'swag_badge_refund',
  ]),
});
export type GetUploadTemplateQuery = z.infer<typeof GetUploadTemplateQuerySchema>;

export const GetUploadTemplateResponseSchema = z.object({
  url: z.string(),
});
export type GetUploadTemplateResponse = z.infer<typeof GetUploadTemplateResponseSchema>;
