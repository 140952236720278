import { Endpoint, Method } from '../../../infrastructure';
import { CalendlyEventTypeSchema } from '../base';
import { CalendlyEventTypeFindSchema } from '../crud';
import { z } from 'zod';

export const CalendlyEventTypeListExtendedSchema = CalendlyEventTypeSchema.extend({
  recruiter_name: z.string().nullable(),
  recruiter_id: z.string().nullable(),
  recruiter_email: z.string().nullable(),
  department: z.string().nullable(),
});
export type CalendlyEventTypeListExtended = z.infer<typeof CalendlyEventTypeListExtendedSchema>;
export const CalendlyEventTypeListExtendedFilterSchema = CalendlyEventTypeFindSchema;

export type CalendlyEventTypeListExtendedFilter = z.infer<
  typeof CalendlyEventTypeListExtendedFilterSchema
>;

export const CalendlyEventTypeListExtendedQuerySchema = z.object({
  filters: CalendlyEventTypeListExtendedFilterSchema.optional(),
});
export type CalendlyEventTypeListExtendedQuery = z.infer<
  typeof CalendlyEventTypeListExtendedQuerySchema
>;

export type CalendlyEventTypeListExtendedResponse = CalendlyEventTypeListExtended[];

export type CalendlyEventTypeListExtendedEndpoint = Endpoint<
  Method.GET,
  `/api/v2/calendly-event-types/extended`,
  CalendlyEventTypeListExtendedResponse,
  undefined,
  CalendlyEventTypeListExtendedQuery,
  undefined
>;
