import {
  Space,
  Select,
  Flex,
  Badge,
  Drawer,
  Typography,
  Button,
  Tooltip,
  Checkbox,
  Switch,
} from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { BellOutlined, CloseOutlined } from '@ant-design/icons';

import map from 'lodash/map';
import get from 'lodash/get';

import NotificationsList from './NotificationsList';
import { FormField } from '@ct-internal/reports';
import { ActionRequestsLabel } from '@ct-internal/api';

import { useSendGtmEvent } from '@shared/gtm';
import BulkActions from './BulkActions';
import { useNotificationContext } from './context';

const NotificationBell = () => {
  const {
    isBulkActions,
    setIsBulkActions,
    isActing,
    commonActions,
    selectedNotifications,
    handleSelectAll,
    notifications,
    filterSetup,
  } = useNotificationContext();

  const sendGtmEvent = useSendGtmEvent();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const showDrawer = () => {
    setIsDrawerOpen(true);

    sendGtmEvent('notification_bell_clicked');
  };

  const onClose = () => {
    setIsDrawerOpen(false);
  };

  const isSelectedAll = notifications?.rows.length === selectedNotifications.length;
  const user = useSelector((state: any) => state?.authentication?.user);

  const isAdminEnable = [
    'jacob.shalev@clevertech.biz',
    'jacob.shalev@lumenalta.com',
    'kuty.shalev@clevertech.biz',
    'kuty.shalev@lumenalta.com',
    'michelle.mcfarland@clevertech.biz',
    'michelle.mcfarland@lumenalta.com',
    'kaio.rodrigues@clevertech.biz',
    'kaio.rodrigues@lumenalta.com',
  ].includes(user.email);

  return (
    <>
      <Flex className="notification-bell" onClick={showDrawer}>
        <Badge count={notifications?.count}>
          <BellOutlined />
        </Badge>
      </Flex>
      <Drawer
        className="notification-drawer"
        title={
          <>
            <Flex align="center" justify="space-between">
              <Typography.Text>Action Requests</Typography.Text>
              <Badge count={notifications?.count} />
            </Flex>
          </>
        }
        footer={
          isBulkActions ? (
            <BulkActions actions={commonActions} selectedNotifications={selectedNotifications} />
          ) : undefined
        }
        placement="right"
        onClose={onClose}
        open={isDrawerOpen}
        forceRender={true}
        width={500}
      >
        <Flex justify="space-between" align="end" style={{ marginBottom: '10px' }}>
          <Space>
            <Typography.Text>Filter by type:</Typography.Text>
            <Select
              allowClear
              options={map(Object.keys(ActionRequestsLabel), (key) => ({
                label: get(ActionRequestsLabel, key),
                value: key,
              }))}
              onChange={(value) =>
                filterSetup.form.setFieldValue(filterSetup.fields.slug.EQUAL as FormField, value)
              }
              style={{ minWidth: '200px' }}
            />
          </Space>

          <Tooltip title={`${isBulkActions ? 'Cancel' : 'Active'} bulk actions`}>
            <Button disabled={isActing} onClick={() => setIsBulkActions(!isBulkActions)}>
              {isBulkActions ? <CloseOutlined /> : null}
              Bulk Actions
            </Button>
          </Tooltip>
        </Flex>
        {isBulkActions && (
          <Checkbox
            className="notification-select-all"
            checked={isSelectedAll}
            onChange={(e) => handleSelectAll(e.target.checked)}
          >
            Select All ({notifications?.rows.length ?? 0})
          </Checkbox>
        )}
        {isAdminEnable ? (
          <Flex justify="space-between" align="end" style={{ marginBottom: '10px' }}>
            <Space>
              <Typography.Text>See as admin:</Typography.Text>
              <Switch
                defaultValue={(filterSetup.filters?.as_admin?.EQUAL as FormField) ?? false}
                onChange={(value) =>
                  filterSetup.form.setFieldValue(
                    filterSetup.fields.as_admin.EQUAL as FormField,
                    value ? 1 : 0,
                  )
                }
              />
            </Space>
          </Flex>
        ) : null}
        <NotificationsList showDrawer={showDrawer} />
      </Drawer>
    </>
  );
};

export default NotificationBell;
