import { z } from 'zod';
import {
  Endpoint,
  Method,
  makeSorter,
  FilterOperations,
  ReportsQuerySchema,
  ReportResponse,
} from '../../../infrastructure';

export const ListTechInterviewsItemSchema = z.object({
  scheduled: z.boolean(),
  tech_recruiter_id: z.number().nullable(),
  tech_recruiter_name: z.string(),
  recruiter_id: z.number(),
  recruiter_name: z.string(),
  position_id: z.number().nullable(),
  position_name: z.string(),
  job_id: z.number(),
  job_title: z.string(),
  interview_date: z.string(),
  interview_id: z.number(),
  is_priority: z.boolean(),
});

export type ListTechInterviewsItem = z.infer<typeof ListTechInterviewsItemSchema>;

export const ListTechInterviewsFiltersSchema = z
  .object({
    ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
    tech_recruiter_id: z.record(z.enum([FilterOperations.IN]), z.string().array()),
    recruiter_id: z.record(z.enum([FilterOperations.IN]), z.string().array()),
  })
  .partial();
export type ListTechInterviewsFilters = z.infer<typeof ListTechInterviewsFiltersSchema>;

export const ListTechInterviewsSorterSchema = makeSorter(z.enum(['tech_recruiter_name']));
export type ListTechInterviewsSorter = z.infer<typeof ListTechInterviewsSorterSchema>;

export const ListTechInterviewsQuerySchema = ReportsQuerySchema.extend({
  filters: ListTechInterviewsFiltersSchema.optional(),
  sorter: ListTechInterviewsSorterSchema.optional(),
});
export type ListTechInterviewsQuery = z.infer<typeof ListTechInterviewsQuerySchema>;

export type ListTechInterviewsResponse = ReportResponse<ListTechInterviewsItem>;

export type ListTechInterviewsEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql/interviews/tech-interviews',
  ListTechInterviewsResponse,
  {},
  ListTechInterviewsQuery,
  {}
>;
