import { Card, Typography, Flex, Tag, Checkbox } from 'antd';
import { NotificationConfig } from './NotificationTypes';

import moment from 'moment';

import { ActionRequest } from '@ct-internal/api';

import { NotificationDate, getDueDateTagColor } from './helpers';
import { ActionComponent, ActionConfig } from './ActionTypes';
import CloseNotification from './ActionTypes/CloseNotification';
import { useNotificationContext } from './context';

interface NotificationCardProps {
  notification: ActionRequest;
  isSelected?: boolean;
}

const NotificationCard = ({ notification, isSelected }: NotificationCardProps) => {
  const { isBulkActions, handleNotificationSelect } = useNotificationContext();

  const config = NotificationConfig[notification.slug];

  const actions: (ActionComponent | undefined)[] = [CloseNotification];

  if (notification.action_components) {
    actions.unshift(
      ...notification.action_components
        .map((key) => ActionConfig[key])
        .filter((component) => !!component),
    );
  }

  const due_date = moment(notification.dt_action_due);

  const title = (
    <Flex flex={1} vertical style={{ marginBottom: 8, marginTop: 8 }}>
      <Flex justify="space-between" align="center">
        <Typography.Text strong>{config?.title}</Typography.Text>
        {notification.dt_action_due && (
          <Flex gap={4}>
            <Typography.Text>Due Date:</Typography.Text>
            <Tag color={getDueDateTagColor(due_date.toISOString())} style={{ margin: 0 }}>
              {due_date.format('ll')}
            </Tag>
          </Flex>
        )}
      </Flex>
      <NotificationDate date={moment(notification.dt_request).toDate()} />
    </Flex>
  );

  return (
    <Card
      className="notification-card"
      size="small"
      title={
        isBulkActions ? (
          <Checkbox
            style={{ width: '100%' }}
            checked={isSelected}
            onChange={(e) =>
              handleNotificationSelect(e.target.checked ? 'check' : 'uncheck', notification)
            }
          >
            {title}
          </Checkbox>
        ) : (
          title
        )
      }
      actions={
        !isBulkActions
          ? actions?.map((Component) => Component && <Component notification={notification} />)
          : undefined
      }
    >
      {config?.getDescription(notification)}
    </Card>
  );
};

export default NotificationCard;
