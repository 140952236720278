import { CalendlyUserSchema } from '../base';
import { z } from 'zod';

export const CalendlyUserCreateSchema = CalendlyUserSchema.pick({
  calendly_user_id: true,
  category: true,
  person_id: true,
  calendly_api_type: true,
  calendly_api_user_id: true,
  calendly_api_user_slug: true,
  calendly_api_user_uri: true,
  calendly_user_type_id: true,
  path: true,
  webhook_re: true,
  email_template: true,
  name: true,
  key: true,
}).partial({
  calendly_user_id: true,
  calendly_api_user_id: true,
  calendly_api_user_slug: true,
  calendly_api_user_uri: true,
  webhook_re: true,
  name: true,
  email_template: true,
  key: true,
  category: true,
  path: true,
  person_id: true,
});

export type CalendlyUserCreate = z.infer<typeof CalendlyUserCreateSchema>;
