import { Badge, Card, Flex, Space, Spin, Typography } from 'antd';

import { ActionRequest, ActionRequestComponentKeys } from '@ct-internal/api';

import { ActionComponent, ActionConfig } from './ActionTypes';
import CloseNotification from './ActionTypes/CloseNotification';
import { useNotificationContext } from './context';

interface NotificationCardProps {
  selectedNotifications: ActionRequest[];
  actions?: ActionRequestComponentKeys[];
}

const defaultBulkActions = [
  ActionRequestComponentKeys.DropInterview,
  ActionRequestComponentKeys.NoShowInterview,
  ActionRequestComponentKeys.InterviewConducted,
];

const BulkActions = ({ selectedNotifications, actions }: NotificationCardProps) => {
  const { isActing } = useNotificationContext();

  const options: (ActionComponent | undefined)[] = [CloseNotification];

  if (actions) {
    options.unshift(...actions.map((key) => ActionConfig[key]).filter((component) => !!component));
  } else {
    options.unshift(
      ...defaultBulkActions.map((key) => ActionConfig[key]).filter((component) => !!component),
    );
  }

  return (
    <div className="notifications-bulk-actions">
      <Card
        className="notification-card"
        actions={options?.map(
          (Component) => Component && <Component bulkNotifications={selectedNotifications} />,
        )}
      >
        <Flex justify="space-between" align="center">
          <Space>
            <Badge color="blue" showZero count={selectedNotifications.length} />
            <Typography.Text>Selected action requests</Typography.Text>
          </Space>

          {isActing && <Spin />}
        </Flex>
        {selectedNotifications.length > 15 && isActing && (
          <Typography.Paragraph type="secondary" style={{ margin: '16px 0 0' }}>
            It may take some time. You can close this drawer, but please keep this window open. We
            will notify you when it's finished.
          </Typography.Paragraph>
        )}
      </Card>
    </div>
  );
};

export default BulkActions;
