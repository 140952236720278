import { z } from 'zod';
import { CalendlyUserTypeSchema } from '../base';

export const CalendlyUserTypeUpdateSchema = CalendlyUserTypeSchema.pick({
  calendly_user_type_id: true,
  application_status: true,
  name: true,
}).partial({
  application_status: true,
  name: true,
});

export type CalendlyUserTypeUpdate = z.infer<typeof CalendlyUserTypeUpdateSchema>;
