import { z } from 'zod';
import { CalendlyUserTypeSchema } from '../base';

export const CalendlyUserTypeCreateSchema = CalendlyUserTypeSchema.pick({
  application_status: true,
  calendly_user_type_id: true,
  name: true,
}).partial({
  calendly_user_type_id: true,
});

export type CalendlyUserTypeCreate = z.infer<typeof CalendlyUserTypeCreateSchema>;
