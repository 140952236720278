import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';

export const DeleteSkillNodeQuerySchema = z
  .object({
    tree: z.enum(['true', 'false']).transform((e) => e === 'true'),
  })
  .partial();

export const DeleteSkillNodeParamsSchema = z.object({
  skill_id: z.string().regex(/^\d+$/, 'Invalid skill id').transform(Number),
});

export type DeleteSkillNodeParams = z.infer<typeof DeleteSkillNodeParamsSchema>;

export type DeleteSkillNodeQuery = z.infer<typeof DeleteSkillNodeQuerySchema>;

export type DeleteSkillNodeRequest = {};

export type DeleteSkillNodeResponse = {};

export type DeleteSkillNodeEndpoint = Endpoint<
  Method.DELETE,
  `/api/v2/sql/skill-node/${DeleteSkillNodeParams['skill_id']}`,
  DeleteSkillNodeResponse,
  DeleteSkillNodeParams,
  DeleteSkillNodeQuery,
  DeleteSkillNodeRequest
>;
