import { CalendlyEventTypeSchema } from '../base';
import { z } from 'zod';

export const CalendlyEventTypeUpdateSchema = CalendlyEventTypeSchema.pick({
  calendly_event_type_id: true,
  duration: true,
  email_template: true,
  is_active: true,
  is_default: true,
  is_priority: true,
  is_secret: true,
  is_enabled: true,
  name: true,
  pooling_type: true,
  scheduling_url: true,
  slug: true,
  uri: true,
}).partial({
  duration: true,
  email_template: true,
  is_active: true,
  is_priority: true,
  is_default: true,
  is_secret: true,
  is_enabled: true,
  name: true,
  pooling_type: true,
  scheduling_url: true,
  slug: true,
  uri: true,
});

export type CalendlyEventTypeUpdate = z.infer<typeof CalendlyEventTypeUpdateSchema>;
