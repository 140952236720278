import { z } from 'zod';
import { dateString, Endpoint, Method } from '../../../infrastructure';

export const ApplicationSetIsInterviewPriorityRequestSchema = z.object({
  is_interview_priority: z.boolean().default(false),
  is_manual: z.boolean().nullable(),
  dt_request_interview_to_be_done: dateString.nullable(),
});

export type ApplicationSetIsInterviewPriorityRequest = z.infer<
  typeof ApplicationSetIsInterviewPriorityRequestSchema
>;

export const ApplicationSetIsInterviewPriorityParamsSchema = z.object({
  application_id: z.string(),
});
export type ApplicationSetIsInterviewPriorityParams = z.infer<
  typeof ApplicationSetIsInterviewPriorityParamsSchema
>;

export type ApplicationSetIsInterviewPriorityResponse = any;

export type ApplicationSetIsInterviewPriorityEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/applications/${ApplicationSetIsInterviewPriorityParams['application_id']}/set-is-interview-priority`,
  ApplicationSetIsInterviewPriorityResponse,
  ApplicationSetIsInterviewPriorityParams,
  undefined,
  ApplicationSetIsInterviewPriorityRequest
>;
