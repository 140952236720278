import { z } from 'zod';
import { Endpoint, Method } from '../../infrastructure';

export const GetPsnAssignmentInfoParamsSchema = z.object({
  person_id: z.string(),
});
export type GetPsnAssignmentInfoParams = z.infer<typeof GetPsnAssignmentInfoParamsSchema>;

export const GetPsnAssignmentInfoItemSchema = z.object({
  person_id: z.string(),
  assignment_id: z.string().nullable(),
  project_id: z.string().nullable(),
  project_name: z.string().nullable(),
  position_id: z.string().nullable(),
  position_name: z.string().nullable(),
  client_id: z.string().nullable(),
  client_name: z.string().nullable(),
  project_url: z.string().nullable(),
});
export type GetPsnAssignmentInfoItem = z.infer<typeof GetPsnAssignmentInfoItemSchema>;

export type GetPsnAssignmentInfoResponse = GetPsnAssignmentInfoItem | null;

export type GetPsnAssignmentInfoEndpoint = Endpoint<
  Method.GET,
  `/api/people/${GetPsnAssignmentInfoParams['person_id']}/psn-assignment-info`,
  GetPsnAssignmentInfoResponse,
  GetPsnAssignmentInfoParams,
  undefined,
  undefined
>;
