import { authService } from '@ct-internal/frontend-utilities';

import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import config from './config';
import qs from 'qs';

// Local development setup
import https from 'https';

const { API_BASE } = config;

const _getPath = async (path: string, opts: any) => {
  try {
    const parsedUrl = /(.*)(\?.*)/.exec(path);
    let urlPath = path;
    let urlQuery = '';
    if (parsedUrl) {
      [, urlPath, urlQuery] = parsedUrl;
    }

    const queryParams = urlQuery ? qs.parse(urlQuery, { ignoreQueryPrefix: true }) : {};

    const params = { ...(opts.qs || opts.params || {}) };

    const queryString = qs.stringify(
      {
        ...queryParams,
        ...params,
      },
      {
        addQueryPrefix: true,
        encode: opts.encode || false,
        arrayFormat: 'comma',
        ...(opts.qsOptions || {}),
      },
    );

    return urlPath + queryString;
  } catch (err) {
    console.error('_getPath err', err);
    return path;
  }
};

const _makeRequest = async (path: string, options = {}, baseUrl: string = API_BASE) => {
  const opts = await getPayload(options);

  const _path = await _getPath(path, opts);
  const requestUrl = `${baseUrl}/api/v2${_path}`;

  const response = await fetch(requestUrl, opts);

  return response;
};

const request = async (path: string, options: any = {}, baseUrl = API_BASE) => {
  try {
    const response = await _makeRequest(path, options, baseUrl);
    const json = await processResponse(response, options);

    if (!response.ok) {
      if (response.status === 401) {
        authService.refreshToken();
      }
      throw createError(json);
    }

    return json;
  } catch (error) {
    handleRequestError(error);
  }
};

const processResponse = async (response: Response, options: any) => {
  if (options.asRaw || response.status === 204) {
    return response;
  }
  return await jsonBody(response);
};

const handleRequestError = (error: any) => {
  if (error.message === 'No valid token available' || error.message === 'Authentication failed') {
    console.error('Authentication error:', error.message);
  }
  throw error;
};

async function getPayload(options: any = {}) {
  const { body } = options;
  const isJson = Object.prototype.toString.call(body) === '[object Object]';

  const agent = new https.Agent({ rejectUnauthorized: config.ENV !== 'local' });

  let headers = isJson ? { 'Content-Type': 'application/json' } : {};

  headers = Object.assign(headers, options.headers, {
    Authorization: await getAuthorization(options),
  });

  const extras = isJson ? { body: JSON.stringify(body) } : {};
  const result = Object.assign({}, options, { headers }, extras, { agent });

  return result;
}

async function getAuthorization({ authorizationHeader }: { authorizationHeader?: string } = {}) {
  if (authorizationHeader) {
    return authorizationHeader;
  }

  try {
    const token = await authService.getValidToken();
    if (!token) {
      throw new Error('No valid token available');
    }
    return `JWT ${token}`;
  } catch (error: any) {
    console.error('Authentication error:', error.message);
    throw error;
  }
}

function createError(data: any) {
  if (_isString(data.message)) {
    return data.message;
  } else if (_isObject(data.error)) {
    return data.error.message;
  } else if (_isString(data.error)) {
    return data.error;
  } else {
    return 'Unexpected error';
  }
}

const jsonBody = async (response: Response) => {
  try {
    return response.json();
  } catch (err) {
    console.warn('The server did not send a JSON response', err);
    return {};
  }
};

export default request;
