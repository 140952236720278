import { Endpoint, Method } from '../../../infrastructure';
import { CalendlyUser, CalendlyUserSchema } from '../base';
import { z } from 'zod';

export const CalendlyUserSyncRequestSchema = CalendlyUserSchema.pick({
  calendly_user_id: true,
});
export type CalendlyUserSyncRequest = z.infer<typeof CalendlyUserSyncRequestSchema>;

export type CalendlyUserSyncResponse = CalendlyUser;

export type CalendlyUserSyncEndpoint = Endpoint<
  Method.POST,
  `/api/v2/calendly-users/sync-calendly`,
  CalendlyUserSyncResponse,
  undefined,
  undefined,
  CalendlyUserSyncRequest
>;
