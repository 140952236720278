import { Method, Endpoint } from '../../../infrastructure';
import { z } from 'zod';

export const UpdateAppSourceChannelRequestSchema = z.object({
  title: z.string().optional(),
});

export type UpdateAppSourceChannelRequest = z.infer<typeof UpdateAppSourceChannelRequestSchema>;

export type UpdateAppSourceChannelResponse = {};

export type UpdateAppSourceChannelEndpoint = Endpoint<
  Method.PATCH,
  '/api/v2/applications/source-channels/:id',
  UpdateAppSourceChannelResponse,
  { id: number },
  undefined,
  UpdateAppSourceChannelRequest
>;
