import { Endpoint, Method } from '../../../infrastructure';
import { CalendlyEventType } from '../base';
import { CalendlyEventTypeFindSchema } from '../crud';
import { z } from 'zod';

export const CalendlyEventTypeListFilterSchema = CalendlyEventTypeFindSchema;

export type CalendlyEventTypeListFilter = z.infer<typeof CalendlyEventTypeListFilterSchema>;

export const CalendlyEventTypeListQuerySchema = z.object({
  filters: CalendlyEventTypeListFilterSchema.optional(),
});
export type CalendlyEventTypeListQuery = z.infer<typeof CalendlyEventTypeListQuerySchema>;

export type CalendlyEventTypeListResponse = CalendlyEventType[];

export type CalendlyEventTypeListEndpoint = Endpoint<
  Method.GET,
  `/api/v2/calendly-event-types`,
  CalendlyEventTypeListResponse,
  undefined,
  CalendlyEventTypeListQuery,
  undefined
>;
