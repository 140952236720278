import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';
import { TJobCategory } from '../../jobs/table-schemas';

export const MoveSkillNodesSchema = z.object({
  parent_skill_id: z.number().nullish(),
  skill_ids: z.array(z.number()),
});

export type MoveSkillNodes = z.infer<typeof MoveSkillNodesSchema>;

export type MoveSkillNodesParams = {};

export type MoveSkillNodesQuery = {};

export type MoveSkillNodesRequest = z.infer<typeof MoveSkillNodesSchema>;

export type MoveSkillNodesResponse = TJobCategory;

export type MoveSkillNodesEndpoint = Endpoint<
  Method.PUT,
  '/api/v2/sql/skill-node',
  MoveSkillNodesResponse,
  MoveSkillNodesParams,
  MoveSkillNodesQuery,
  MoveSkillNodesRequest
>;
