import { z } from 'zod';
import { dateString } from '../../infrastructure';

export const CalendlyEventTypeSchema = z.object({
  calendly_event_type_id: z.string(),
  calendly_user_id: z.string(),

  is_enabled: z.boolean(),
  is_default: z.boolean(),
  is_priority: z.boolean(),
  email_template: z.string().nullable(),

  calendly_api_event_type_id: z.string().nullable(),
  is_secret: z.boolean(),
  is_active: z.boolean(),
  slug: z.string().nullable(),
  uri: z.string().nullable(),

  scheduling_url: z.string(),
  duration: z.number(),
  name: z.string().nullable(),
  pooling_type: z.string().nullable(),

  dt_created: dateString,
  dt_updated: dateString,
});
export type CalendlyEventType = z.infer<typeof CalendlyEventTypeSchema>;

export const CalendlyAPIEventTypeSchema = z.object({
  kind: z.string(),
  name: z.string(),
  pooling_type: z.string(),
  scheduling_url: z.string(),
  slug: z.string(),
  uri: z.string(),
  active: z.boolean().nullable(),
  secret: z.boolean().nullable(),
  duration: z.number(),
  profile: z.object({
    owner: z.string(),
    type: z.string(),
  }),
});
export type CalendlyAPIEventType = z.infer<typeof CalendlyAPIEventTypeSchema>;
