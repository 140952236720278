import { Endpoint, Method } from '../../../infrastructure';
import { CalendlyUser } from '../base';
import { CalendlyUserUpdateSchema } from '../crud';
import { z } from 'zod';

export const CalendlyUserUpdateRequestSchema = CalendlyUserUpdateSchema.pick({
  calendly_api_type: true,
  calendly_api_user_id: true,
  category: true,
  calendly_user_type_id: true,
  path: true,
  email_template: true,
  key: true,
  name: true,
});
export type CalendlyUserUpdateRequest = z.infer<typeof CalendlyUserUpdateRequestSchema>;

export type CalendlyUserUpdateParams = Pick<CalendlyUser, 'calendly_user_id'>;

export type CalendlyUserUpdateResponse = CalendlyUser;

export type CalendlyUserUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/calendly-users/${CalendlyUserUpdateParams['calendly_user_id']}`,
  CalendlyUserUpdateResponse,
  CalendlyUserUpdateParams,
  undefined,
  CalendlyUserUpdateRequest
>;
