import { useEffect, useMemo, useState } from 'react';
import { Form, FormInstance } from 'antd';
import {
  addFilter,
  createFilterFields,
  FilterSchema,
  getFilterDescriptor,
  InferFilterFieldValues,
  InferFilterQuery,
  toFilterValues,
} from '@ct-internal/api';

import { FiltersSetup } from '../../types';
import { validateSchemaValues } from '../../utils/validation';

export const useFilters = <T extends FilterSchema>(
  filterSchema: T,
  initialFilters: InferFilterQuery<T> = {},
  localStoragePersistenceConfig?: {
    key: string; // Key to store filters in localStorage
    persist: boolean; // Turn to false to delete persisted filters from localStorage
  },
): FiltersSetup<T> => {
  const [updatesCount, setUpdatesCount] = useState(0);
  const [form] = Form.useForm<InferFilterFieldValues<T>>();

  const initialFiltersValue = useMemo(() => {
    if (!localStoragePersistenceConfig) {
      return initialFilters;
    }
    const { key, persist } = localStoragePersistenceConfig;
    if (localStorage.getItem(key) && persist) {
      return JSON.parse(localStorage.getItem(key) as string) as InferFilterQuery<T>;
    }
    return initialFilters;
  }, [initialFilters, localStoragePersistenceConfig]);

  const initialFormattedValues = useMemo(
    () => toFilterValues<T>(initialFiltersValue) as InferFilterFieldValues<T>,
    // Initialisation
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    const onMountFormValues = form.getFieldsValue(true);
    if (
      Object.keys(initialFiltersValue).length > 0 &&
      Object.keys(onMountFormValues).length === 0
    ) {
      // This handles case when there is no actual form in the dom
      form.setFieldsValue(initialFormattedValues);
    }
    // eslint-disable-next-line
  }, []);

  // Define filter values before ant initializes form
  const filterValues = updatesCount === 0 ? initialFormattedValues : form.getFieldsValue(true);

  const filters = useMemo(() => {
    return Object.entries(filterValues).reduce((result, [key, value]) => {
      const [field, operator] = getFilterDescriptor<InferFilterQuery<T>>(key);

      // Omit empty values
      if (value === '' || value === undefined) {
        return result;
      }

      return addFilter(result, field, operator, value as any);
    }, {} as InferFilterQuery<T>);
  }, [filterValues]);

  useEffect(() => {
    if (localStoragePersistenceConfig?.persist) {
      localStorage.setItem(localStoragePersistenceConfig.key, JSON.stringify(filters));
    }
  }, [filters, localStoragePersistenceConfig]);

  const errors = useMemo(
    () => validateSchemaValues(filterSchema, filters),
    [filterSchema, filters],
  );

  useEffect(() => {
    // Clean old errors
    const fieldsStatus = form
      .getFieldsError()
      // Filter out those that still a have error
      .filter(({ name }) => !errors.errors?.[name[0]])
      // Mark fields without error as valid
      .reduce((result, { name }) => {
        const [field] = name;
        result.push({
          name: field as Parameters<typeof form.getFieldError>[0],
          errors: [],
        });
        return result;
      }, [] as Parameters<typeof form.setFields>[0]);

    // Add new errors
    const errorFields = Object.keys(errors.errors || {});
    errorFields.reduce((result, field) => {
      result.push({
        name: field as Parameters<typeof form.getFieldError>[0],
        errors: [errors.errors?.[field] as string],
      });
      return result;
    }, fieldsStatus);
    form.setFields(fieldsStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const fields = useMemo(() => createFilterFields<T>(filterSchema), [filterSchema]);

  // This decorator helps us trigger state update when a field changes,
  // since ant doesn't re-renders state on setFieldValue.
  const forceUpdate = () => {
    setUpdatesCount((prevValue) => prevValue + 1);
  };
  const formDecorator = new Proxy(form, {
    get: (target, prop, parent) => {
      if (prop === 'setFieldsValue' || prop === 'setFieldValue') {
        return (...args: any[]) => {
          // @ts-ignore
          Reflect.get(target, prop, parent)(...args);
          forceUpdate();
        };
      }
      if (prop === 'resetFields') {
        return (fields?: string[]) => {
          // @ts-ignore
          Reflect.get(target, prop, parent)(fields);
          // Clear specific fields in the form state
          if (fields) {
            const currentValues = form.getFieldsValue(true);
            const clearedValues = { ...currentValues };

            fields.forEach((field) => {
              // Clear all fields that match exactly or start with the field name
              Object.keys(clearedValues).forEach((key) => {
                if (key === field || key.startsWith(`${field}_`)) {
                  clearedValues[key] = undefined;
                }
              });
            });

            form.setFieldsValue(clearedValues);
          } else {
            // Clear all fields in the form state
            const currentValues = form.getFieldsValue(true);
            const clearedValues = { ...currentValues };
            Object.keys(clearedValues).forEach((key) => {
              clearedValues[key] = undefined;
            });
            form.setFieldsValue(clearedValues);
          }
          forceUpdate();
        };
      }
      return Reflect.get(target, prop);
    },
  });

  const ready = Object.keys(errors).length === 0;

  return {
    form: formDecorator as unknown as FormInstance<InferFilterQuery<T>>,
    filters,
    fields,
    initialValues: initialFormattedValues,
    onValuesChange: forceUpdate,
    errors,
    ready,
  };
};
