import { Endpoint, Method } from '../../../infrastructure';
import { CalendlyUserType } from '../base';
import { CalendlyUserTypeUpdateSchema } from '../crud';
import { z } from 'zod';

export const CalendlyUserTypeUpdateRequestSchema = CalendlyUserTypeUpdateSchema.pick({
  name: true,
  application_status: true,
});
export type CalendlyUserTypeUpdateRequest = z.infer<typeof CalendlyUserTypeUpdateRequestSchema>;

export type CalendlyUserTypeUpdateParams = Pick<CalendlyUserType, 'calendly_user_type_id'>;

export type CalendlyUserTypeUpdateResponse = CalendlyUserType;

export type CalendlyUserTypeUpdateEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/calendly-user-types/${CalendlyUserTypeUpdateParams['calendly_user_type_id']}`,
  CalendlyUserTypeUpdateResponse,
  CalendlyUserTypeUpdateParams,
  undefined,
  CalendlyUserTypeUpdateRequest
>;
