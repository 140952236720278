import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';

export const OnDeckPipelineSummaryItemSchema = z.object({
  category: z.string(),
  job_family: z.string(),
  solution_architect: z.number(),
  tech_lead: z.number(),
  all: z.number(),
  senior_under_n: z.number(),
});

export const OnDeckPipelineSummaryFiltersSchema = z
  .object({
    is_gem: z.record(z.enum([FilterOperations.EQUAL]), z.enum(['true', 'false'])),
    stale_max_days: z.record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number()),
    salary_max: z.record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number()),
  })
  .partial();

export type OnDeckPipelineSummaryFilters = z.infer<typeof OnDeckPipelineSummaryFiltersSchema>;

export const OnDeckPipelineSummaryQuerySchema = z.object({
  filters: OnDeckPipelineSummaryFiltersSchema.optional(),
});

export type OnDeckPipelineSummaryItem = z.infer<typeof OnDeckPipelineSummaryItemSchema>;

export type OnDeckPipelineSummaryResponse = OnDeckPipelineSummaryItem[];

export type OnDeckPipelineSummaryQuery = z.infer<typeof OnDeckPipelineSummaryQuerySchema>;

export type OnDeckPipelineSummaryEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql/on-deck/pipeline`,
  OnDeckPipelineSummaryResponse,
  undefined,
  OnDeckPipelineSummaryQuery,
  undefined
>;
