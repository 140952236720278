import { Endpoint, Method } from '../../../infrastructure';
import { CalendlyUserType } from '../base';
import { CalendlyUserTypeCreateSchema } from '../crud';
import { z } from 'zod';

export const CalendlyUserTypeCreateRequestSchema = CalendlyUserTypeCreateSchema.pick({
  name: true,
  application_status: true,
});
export type CalendlyUserTypeCreateRequest = z.infer<typeof CalendlyUserTypeCreateRequestSchema>;

export type CalendlyUserTypeCreateResponse = CalendlyUserType;

export type CalendlyUserTypeCreateEndpoint = Endpoint<
  Method.POST,
  `/api/v2/calendly-user-types`,
  CalendlyUserTypeCreateResponse,
  undefined,
  undefined,
  CalendlyUserTypeCreateRequest
>;
