import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

export const PsnMonthlyRevCostSchema = z.object({
  ymonth: z.number(),
  name: z.string(),
  person_id: z.string(),
  gravatar_url: z.string(),
  email: z.string(),
  department_id: z.string(),
  department: z.string(),

  project_id: z.string().nullable(),
  project_name: z.string().nullable(),
  client_id: z.string().nullable(),
  client_name: z.string().nullable(),

  revenue: z.number(),
  cost: z.number(),
  profit: z.number(),
  margin: z.number(),
});
export type PsnMonthlyRevCost = z.infer<typeof PsnMonthlyRevCostSchema>;

export const PsnMonthlyRevCostFiltersSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
  ymonth_start: z
    .record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth_end: z.record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number()).optional(),
  department_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
  name: z.record(z.enum([FilterOperations.CONTAIN]), z.string()).optional(),
});
export type PsnMonthlyRevCostFilters = z.infer<typeof PsnMonthlyRevCostFiltersSchema>;
export const PsnMonthlyRevCostQuerySchema = z.object({
  filters: PsnMonthlyRevCostFiltersSchema.optional(),
});
export type PsnMonthlyRevCostQuery = z.infer<typeof PsnMonthlyRevCostQuerySchema>;

export type PsnMonthlyRevCostResponse = PsnMonthlyRevCost[];

export type PsnMonthlyRevCostEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql-reports/psn-monthly-rev-cost`,
  PsnMonthlyRevCostResponse,
  undefined,
  PsnMonthlyRevCostQuery,
  undefined
>;
