import { z } from 'zod';

import {
  Endpoint,
  Method,
  makeSorter,
  FilterOperations,
  ReportsQuerySchema,
  ReportResponse,
} from '../../../infrastructure';

import { AppDetailSchema } from '../../application';

const ApplicantExpandedSchema = AppDetailSchema.pick({
  application_id: true,
  contract_status: true,
  name: true,
  email: true,
  availability: true,
  seniority: true,
  english_level: true,
  yoe: true,
  status: true,
  dt_applied: true,
  excitement_rate: true,
  country: true,
  country_code: true,
  city: true,
  state: true,
  timezone: true,
  timezone_number: true,
  timezone_short: true,
  latitude: true,
  longitude: true,
  expected_contract_type: true,
}).extend({
  match_score: z.number(),
  mgmt_reviewed: z.boolean(),
  is_gem: z.boolean(),
  is_stale: z.boolean(),
  is_aged: z.boolean(),
  is_exact_match: z.boolean(),
  is_close_match: z.boolean(),
  is_partial_match: z.boolean(),
  days_passed: z.number(),
  dtupdate: z.string().nullable(),
  last_update: z.string().nullable(),
  dt_update_interview: z.string().nullable(),
  dt_update_comment: z.string().nullable(),
  summary: z
    .object({
      comment_id: z.number(),
      comment: z.string(),
    })
    .nullable(),
  recruiter: z
    .object({
      applicant_id: z.string(),
      name: z.string(),
      email: z.string(),
    })
    .nullable(),
  author: z
    .object({
      applicant_id: z.string(),
      name: z.string(),
      email: z.string(),
    })
    .nullable(),
  job: z
    .object({
      job_id: z.string(),
      job_name: z.string(),
      description: z.string(),
    })
    .nullable(),
  current_stage: z.string().nullable(),
});

export const OnDeckReportJobApplicationSchema = z.object({
  application_id: z.string(),
  job_id: z.string(),
  recruiter_id: z.string(),
  author_id: z.string().nullable(),
  cost: z.number().nullable(),
  usd_salary: z.number().nullable(),
  currency: z.string().nullable(),
  currency_cost: z.number().nullable(),
  currency_salary: z.number().nullable(),
  stage: z.number().nullable(),
  app_detail: ApplicantExpandedSchema,
  name: z.string(),
  match_score: z.number(),
});

export type OnDeckReportJobApplication = z.infer<typeof OnDeckReportJobApplicationSchema>;

export const OnDeckReportJobMatchSchema = z.enum(['exact', 'close', 'partial']);

export const OnDeckReportJobCountryCodeSchema = z.enum(['USCA', 'BR', 'EU', 'OTHER']);

export type OnDeckReportJobCountryCode = z.infer<typeof OnDeckReportJobCountryCodeSchema>;

export type OnDeckReportJobMatch = z.infer<typeof OnDeckReportJobMatchSchema>;

export const OnDeckReportJobFiltersSchema = z
  .object({
    yoe: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
    recruiter_id: z.record(z.enum([FilterOperations.IN]), z.string().array()),
    is_gem: z.record(z.enum([FilterOperations.EQUAL]), z.enum(['true', 'false'])),
    is_lead: z.record(z.enum([FilterOperations.EQUAL]), z.enum(['true', 'false'])),
    is_ss: z.record(z.enum([FilterOperations.EQUAL]), z.enum(['true', 'false'])),
    match_type: z.record(z.enum([FilterOperations.IN]), OnDeckReportJobMatchSchema.array()),
    country_code: z.record(z.enum([FilterOperations.IN]), OnDeckReportJobCountryCodeSchema.array()),
    salary_min: z.record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), z.coerce.number()),
    salary_max: z.record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number()),
    stale_max_days: z.record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number()),
  })
  .partial();

export type OnDeckReportJobFilters = z.infer<typeof OnDeckReportJobFiltersSchema>;

export const OnDeckReportJobSorterSchema = makeSorter(
  z.enum(['status', 'stage', 'yoe', 'recruiter_name', 'match_score']),
);

export type OnDeckReportJobSorter = z.infer<typeof OnDeckReportJobSorterSchema>;

export const OnDeckReportJobQuerySchema = ReportsQuerySchema.extend({
  filters: OnDeckReportJobFiltersSchema.optional(),
  sorter: OnDeckReportJobSorterSchema.optional(),
});

export type OnDeckReportApplicationsParams = {
  job_id: string;
};

export type OnDeckReportJobApplicationsQuery = z.infer<typeof OnDeckReportJobQuerySchema>;

export type OnDeckReportJobApplicationsResponse = ReportResponse<OnDeckReportJobApplication>;

export type OnDeckReportJobApplicationsEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql/on-deck/report/${OnDeckReportApplicationsParams['job_id']}`,
  OnDeckReportJobApplicationsResponse,
  OnDeckReportApplicationsParams,
  OnDeckReportJobApplicationsQuery,
  {}
>;
