import { message as Message } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MutationParams, MutationOptions, MutationResult } from '@src/shared/react-query';
import APIRequest from '@src/shared/request';
import { InterviewCalendarEventEndpoints } from '@ct-internal/api';
import { INTERVIEW_CALENDAR_EVENT_KEY } from './keys';

const getKeysToInvalidate = () => [[INTERVIEW_CALENDAR_EVENT_KEY]];
export function useICEInitiateGoogleEvent(
  options?: MutationOptions<InterviewCalendarEventEndpoints['initiateGoogleEvent']>,
): MutationResult<InterviewCalendarEventEndpoints['initiateGoogleEvent']> {
  return useMutation({
    mutationFn: ({
      request,
    }: MutationParams<InterviewCalendarEventEndpoints['initiateGoogleEvent']>) => {
      return APIRequest(`/interview-calendar-events/initiate-google-event`, {
        method: 'POST',
        body: request,
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      });
    },
    onSuccess: (data, variables, context) => {
      if (variables.request?.exists_pending) {
        Message.success(
          `Interview rescheduled successfully in Google Calendar for: ${variables?.request?.dt_start}`,
        );
      } else {
        Message.success(
          `Interview booked successfully in Google Calendar for: ${variables?.request?.dt_start}`,
        );
      }

      options?.onSuccess?.(data, variables, context);
    },
    onError: (e: any) => {
      Message.error(`Error booking Interview in Google Calendar: ${e.message ?? e}`);
    },
  });
}

export function useICEBookAsPriority(
  options?: MutationOptions<InterviewCalendarEventEndpoints['slotSwapManually']>,
): MutationResult<InterviewCalendarEventEndpoints['slotSwapManually']> {
  return useMutation({
    mutationFn: ({
      request,
    }: MutationParams<InterviewCalendarEventEndpoints['slotSwapManually']>) => {
      return APIRequest(`/interview-calendar-events/slot-swap-manually`, {
        method: 'POST',
        body: request,
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      });
    },
    onSuccess: (data, variables, context) => {
      Message.success(`Interview booked successfully`);

      options?.onSuccess?.(data, variables, context);
    },
    onError: (e: any) => {
      Message.error(`Error booking Interview: ${e.message ?? e}`);
    },
  });
}

export function useICEManualLinkGoogleEvent(
  options?: MutationOptions<InterviewCalendarEventEndpoints['manualLinkGoogleEvent']>,
): MutationResult<InterviewCalendarEventEndpoints['manualLinkGoogleEvent']> {
  return useMutation({
    mutationFn: ({
      request,
    }: MutationParams<InterviewCalendarEventEndpoints['manualLinkGoogleEvent']>) => {
      return APIRequest(`/interview-calendar-events/manual-link-google-event`, {
        method: 'POST',
        body: request,
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      });
    },
    onSuccess: (data, variables, context) => {
      Message.success(
        `Link between Interview Request and google event created successfully: ${variables?.request?.dt_start}`,
      );

      options?.onSuccess?.(data, variables, context);
    },
    onError: (e: any) => {
      Message.error(e.message ?? e);
    },
  });
}

export function useICEInitiate(
  options?: MutationOptions<InterviewCalendarEventEndpoints['initiate']>,
): MutationResult<InterviewCalendarEventEndpoints['initiate']> {
  return useMutation({
    mutationFn: ({ request }: MutationParams<InterviewCalendarEventEndpoints['initiate']>) => {
      return APIRequest(`/interview-calendar-events/initiate`, {
        method: 'POST',
        body: request,
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      });
    },
    onSuccess: (data, variables, context) => {
      Message.success(`Interview initiated successfully`);

      options?.onSuccess?.(data, variables, context);
    },
    onError: (e: any) => {
      Message.error(`Error initiating Interview Request: ${e.message ?? e}`);
    },
  });
}

export function useICESetDropped(
  options?: MutationOptions<InterviewCalendarEventEndpoints['setICEDropped']>,
): MutationResult<InterviewCalendarEventEndpoints['setICEDropped']> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ params }: MutationParams<InterviewCalendarEventEndpoints['setICEDropped']>) => {
      return APIRequest(
        `/interview-calendar-events/${params?.interview_calendar_event_id}/dropped`,
        {
          method: 'PUT',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        },
      );
    },
    onSuccess: (data, variables, context) => {
      Message.success(`Interview Event changed to dropped successfully`);
      queryClient.invalidateQueries({ queryKey: ['generic-key'] });
      options?.onSuccess?.(data, variables, context);
    },
    onError: (e: any) => {
      Message.error(`Error changing Interview Event to Dropped: ${e.message ?? e}`);
    },
  });
}

export function useBulkICESetDropped(
  options?: MutationOptions<InterviewCalendarEventEndpoints['bulkSetICEDropped']>,
): MutationResult<InterviewCalendarEventEndpoints['bulkSetICEDropped']> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      request,
    }: MutationParams<InterviewCalendarEventEndpoints['bulkSetICEDropped']>) => {
      return APIRequest(`/interview-calendar-events/bulk-set-dropped`, {
        method: 'POST',
        body: request,
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      });
    },
    onSuccess: (data, variables, context) => {
      Message.success(`Interview Events changed to dropped successfully`);
      queryClient.invalidateQueries({ queryKey: ['generic-key'] });
      options?.onSuccess?.(data, variables, context);
    },
    onError: (e: any) => {
      Message.error(`Error changing Interview Events to Dropped: ${e.message ?? e}`);
    },
  });
}

export function useICESetConducted(
  options?: MutationOptions<InterviewCalendarEventEndpoints['setICEConducted']>,
): MutationResult<InterviewCalendarEventEndpoints['setICEConducted']> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      params,
    }: MutationParams<InterviewCalendarEventEndpoints['setICEConducted']>) => {
      return APIRequest(
        `/interview-calendar-events/${params?.interview_calendar_event_id}/conducted`,
        {
          method: 'PUT',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        },
      );
    },
    onSuccess: (data, variables, context) => {
      Message.success(`Interview Event changed to Conducted successfully`);

      options?.onSuccess?.(data, variables, context);
      getKeysToInvalidate().forEach((queryKey) => {
        queryClient.refetchQueries({ queryKey });
      });
    },
    onError: (e: any) => {
      Message.error(`Error changing Interview Event to Conducted: ${e.message ?? e}`);
    },
  });
}

export function useBulkICESetConducted(
  options?: MutationOptions<InterviewCalendarEventEndpoints['bulkSetICEConducted']>,
): MutationResult<InterviewCalendarEventEndpoints['bulkSetICEConducted']> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      request,
    }: MutationParams<InterviewCalendarEventEndpoints['bulkSetICEConducted']>) => {
      return APIRequest(`/interview-calendar-events/bulk-set-conducted`, {
        method: 'POST',
        body: request,
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      });
    },
    onSuccess: (data, variables, context) => {
      Message.success(`Interview Events changed to Conducted successfully`);
      queryClient.invalidateQueries({ queryKey: ['generic-key'] });
      options?.onSuccess?.(data, variables, context);
    },
    onError: (e: any) => {
      Message.error(`Error changing Interview Events to Conducted: ${e.message ?? e}`);
    },
  });
}

export function useICESetNoShow(
  options?: MutationOptions<InterviewCalendarEventEndpoints['setICENoShow']>,
): MutationResult<InterviewCalendarEventEndpoints['setICENoShow']> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ params }: MutationParams<InterviewCalendarEventEndpoints['setICENoShow']>) => {
      return APIRequest(
        `/interview-calendar-events/${params?.interview_calendar_event_id}/no-show`,
        {
          method: 'PUT',
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        },
      );
    },
    onSuccess: (data, variables, context) => {
      Message.success(`Interview Event changed to No Show successfully`);

      options?.onSuccess?.(data, variables, context);
      getKeysToInvalidate().forEach((queryKey) => {
        queryClient.refetchQueries({ queryKey });
      });
    },
    onError: (e: any) => {
      Message.error(`Error changing Interview Event to No Show: ${e.message ?? e}`);
    },
  });
}

export function useBulkICESetNoShow(
  options?: MutationOptions<InterviewCalendarEventEndpoints['bulkSetICENoShow']>,
): MutationResult<InterviewCalendarEventEndpoints['bulkSetICENoShow']> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      request,
    }: MutationParams<InterviewCalendarEventEndpoints['bulkSetICENoShow']>) => {
      return APIRequest(`/interview-calendar-events/bulk-set-no-show`, {
        method: 'POST',
        body: request,
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      });
    },
    onSuccess: (data, variables, context) => {
      Message.success(`Interview Events changed to No Show successfully`);
      queryClient.invalidateQueries({ queryKey: ['generic-key'] });
      options?.onSuccess?.(data, variables, context);
    },
    onError: (e: any) => {
      Message.error(`Error changing Interview Events to No Show: ${e.message ?? e}`);
    },
  });
}

export function useICESwitchRecruiter(
  options?: MutationOptions<InterviewCalendarEventEndpoints['switchRecruiter']>,
): MutationResult<InterviewCalendarEventEndpoints['switchRecruiter']> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      params,
      request,
    }: MutationParams<InterviewCalendarEventEndpoints['switchRecruiter']>) => {
      return APIRequest(
        `/interview-calendar-events/${params?.interview_calendar_event_id}/switch-recruiter`,
        {
          method: 'PUT',
          body: request,
          headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        },
      );
    },
    onSuccess: (data, variables, context) => {
      Message.success(`Switch recruiter successfully`);

      options?.onSuccess?.(data, variables, context);
      getKeysToInvalidate().forEach((queryKey) => {
        queryClient.refetchQueries({ queryKey });
      });
    },
    onError: (e: any) => {
      Message.error(`Error switching recruiter: ${e.message ?? e}`);
    },
  });
}
