/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router';

export function useRememberLastTabSelected({
  defaultTab,
  tabsetKey,
  saveToSessionStorage = false,
}: {
  defaultTab: string;
  tabsetKey: string;
  saveToSessionStorage?: boolean;
}): [string, (key: string) => void] {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const setSelectedTab = (key: string) => {
    const updatedSearchParams = new URLSearchParams(search);
    updatedSearchParams.set('tab', key);

    history.push({
      pathname,
      search: updatedSearchParams.toString(),
    });

    if (saveToSessionStorage) {
      sessionStorage.setItem(tabsetKey, key);
    }
  };

  useEffect(() => {
    if (saveToSessionStorage) {
      const lastTabSelected = sessionStorage.getItem(tabsetKey);
      if (lastTabSelected) {
        setSelectedTab(lastTabSelected);
      }
    } else {
      sessionStorage.removeItem(tabsetKey);
    }
  }, []);

  const selectedTab = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    return searchParams.get('tab') ?? defaultTab;
  }, [search, defaultTab]);

  return [selectedTab, setSelectedTab];
}
