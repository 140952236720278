//RESPONSIBLE RECRUITERS
import request from '../../shared/request';

export function getFocusReport() {
  return [];
}

export function createResponsible(payload) {
  return request('/responsible-recruiters', {
    method: 'POST',
    body: payload,
  });
}

export function removeResponsible(id) {
  return request(`/responsible-recruiters/${id}`, {
    method: 'DELETE',
  });
}
