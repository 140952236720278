import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const UploadRefundRequestSchema = z.object({
  fileUpstream: z.any().optional(),
});
export type UploadRefundRequest = z.infer<typeof UploadRefundRequestSchema>;

export type UploadRefundEndpoint = Endpoint<
  Method.POST,
  '/api/v2/badges/upload-refund',
  {},
  undefined,
  undefined,
  UploadRefundRequest
>;

export const UploadRefundRowErrorSchema = z.object({
  orderId: z.string(),
  name: z.string(),
  amount: z.number(),
  errorMessage: z.string(),
});
export type UploadRefundRowError = z.infer<typeof UploadRefundRowErrorSchema>;

export type UploadRefundRow = {
  orderId: string;
  name: string;
  amount: number;
};

export const UploadRefundResponseSchema = z.object({
  total: z.number(),
  processed: z.number(),
  failures: z.number(),
  failedRecords: z.array(UploadRefundRowErrorSchema),
});
export type UploadRefundResponse = z.infer<typeof UploadRefundResponseSchema>;
