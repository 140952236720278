import { InterviewRequestSchema } from '../../interview-request';
import { z } from 'zod';
import { dateString, Endpoint, Method } from '../../../infrastructure';

export const ICEInitiateGoogleEventRequestSchema = InterviewRequestSchema.pick({
  application_id: true,
  stage: true,
})
  .extend({
    recruiter_email: z.string(),
    exists_pending: z.boolean().nullish(),
    dt_start: dateString,
    dt_end: dateString,
  })
  .partial({
    stage: true,
  });

export type ICEInitiateGoogleEventRequest = z.infer<typeof ICEInitiateGoogleEventRequestSchema>;

export type ICEInitiateGoogleEventResponse = {};

export type ICEInitiateGoogleEventEndpoint = Endpoint<
  Method.POST,
  '/api/v2/interview-calendar-events/initiate-google-event',
  ICEInitiateGoogleEventResponse,
  undefined,
  undefined,
  ICEInitiateGoogleEventRequest
>;
